import React, { useEffect, useState } from 'react';
import Styles from './Login.module.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { auth, toaster, baseURL, siteLogo, backgroundColor, backgroundWithOpacity } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';


function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const token = searchParams.get("token") !== null ? searchParams.get("token") : '';
    const email = searchParams.get("email") !== null ? searchParams.get("email") : '';
    const [loading, setLoading] = useState(false);    

    const navigate = useNavigate();
    const formHandler = async (event) => {
      event.preventDefault();
      if (!loading) {
      if (email == "" || token == "" || password == "" || confirmPassword == "")  return toaster("error", "Please fill all required fields");
      if (email.length < 3)  return toaster("error", "Invalid email supplied ");
      if (password.length < 2)  return toaster("error", "Invalid password supplied ");
      if (password !== confirmPassword) return toaster("error", "Passwords do not macth");
      setLoading(true);
      axios.defaults.headers.common = {'Content-Type': 'application/json'}
      await axios.post(baseURL+"/user/reset-password",  {
            email: email, 
            token: token, 
            password: password, 
            confirm_password: confirmPassword
        }).then((response) => {
          if (response.data.code !== 200 && response.data.status == "failed") {
            setLoading(false);
            return toaster("error", response.data.message);
          } else if (response.data.code == 200 && response.data.status == "success") {
            setLoading(false);
            return toaster("success", response.data.message);
          } else {
            setLoading(false);
          }
        }).catch((e)=>{
            // console.log(e);
            setLoading(false);
            alert(error.whoops)
            return e;
        })
      }
    }


      useEffect(() => { 
        
        var body = document.getElementById("body-tag");
        body.classList.add("auth-bg");
        

        if (auth() == true) return navigate("/")                    
                        if(token == "" || token == undefined || email == "" || email == undefined) return navigate('/forgot-password')
                    } , 
                    
            []);

  return (
    <div>


<ToastContainer /> 



<div className='pt-2 my-5'>
<div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>



<div className={"card card-style " + Styles.login_card }>


<div className="content">


     {/* <p className="font-600 color-highlight mb-n1">Let's start</p> */}
<div>
<h1 className="font-25 float-start">Reset Password</h1>
<img src={siteLogo} style={{  width: "45px" }} className="float-end"/>
</div>


<br/>
<p style={{ marginBottom: "20px", paddingTop: "16px" }}>
Set new password for your account
</p>





<form method='post' onSubmit={formHandler}>

<div className="input-style has-borders has-icon validate-field mb-4">
<i className="fas fa-key"></i>
<input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter new password" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Password</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div className="input-style has-borders has-icon validate-field mb-4">
<i className="fas fa-key"></i>
<input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Confirm new password" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">passsword</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<button disabled={loading}
className={"btn btn-full rounded-sm btn-block shadow-l mb-3 mt-4 shadow-bg-m shadow-s " + Styles.login_btn }
style={{background: loading ? backgroundWithOpacity : backgroundColor }}
>
  { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { loading ? '' : 'Reset' }


</button>

</form>





<div className="row pt-2 mb-2">
<div className="col-9 text-start">
<Link to="" className="color-highlight">I can remember my password</Link>
</div>
 <div className="col-3 text-end">
<Link to='/login' className="color-highlight"> Login </Link>
</div>
</div>



</div>
</div>
</div>
</div>

</div>
</div>


    </div>
  )
}

export default ResetPassword
