import Styles from './Receipt.module.css';
import { useEffect, useReducer, useState } from 'react';
import { baseURL, scrollToTop, toaster, user, siteName, storage, date} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadingReducer } from '../reducers/loading-reducer';

function Receipt() {

  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
    receipt();
  }, []);


  function receipt() {
    let data = storage("retrieve", "receipt", null, 'sessionStorage');
    // console.log(data);
    if (data) {
      if (typeof(data) === "object") {        
          setDetails(data);
          // console.log(data);
          // setNumberArray(data.number.split(','));
          // console.log(data.number.split(','));
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  
  }



  function TranxStatus(props) {
    let badgeClass = null;
    switch(props.status) {  
      case "success": badgeClass = "bg-green";
      break;
      case "reversed": badgeClass = "bg-red";
      break;
      case "cancel": badgeClass = "bg-red";    
      break;
      case "pending": badgeClass = "bg-warning";
       break;
      case "initiated": badgeClass = "bg-warning";
      break;
      default: badgeClass = "bg-warning";
    }
    return (
      <>
        <span className={"mt-0 badge border-0 " + badgeClass}>{props.status}</span>
      </>
    )
  
  }




  function TranxImg(props) {
    let receiptImg = null;
    switch(props.status) {  
      case "success": receiptImg= "/dashboard/images/tranx-success.png";
      break;
      case "reversed": receiptImg = "/dashboard/images/tranx-failed.png";
      break;
      case "cancel": receiptImg = "/dashboard/images/tranx-failed.png";    
      break;
      case "pending": receiptImg = "/dashboard/images/warning.png";
       break;
      case "initiated": receiptImg = "/dashboard/images/warning.png";
      break;
      default: receiptImg = receiptImg = "/dashboard/images/warning.png";
    }
    return (
      <>
        <img src={receiptImg} style={{ borderRadius: "50%" }} width="55" className="shadow-xl" />
      </>
    )
  
  }





  const [details, setDetails] = useState({});
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [numberArray, setNumberArray] = useState([]);


  const downloadPDF = async () => {
    dispatch({type: 'start'});
    axios.defaults.headers.common['Authorization'] = "Bearer " + user().token;
    await axios.get(baseURL+"/transaction/download/"+details.reference, {responseType: 'blob'})
    .then((response) => {
        // console.log(response.data);
        // window.open(URL.createObjectURL(response.data));
        dispatch({type: 'stop'});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'receipt-' + details.reference + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

      if (response.data.status == "failed" && response.data.message !== "") {
        // return toaster("error", response.data.message);
      } else if (response.data.status == "success") {

      }
    }).catch((e) => {   
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
        // return toaster("error", error.loading.excemption);
        // return e;
    })


  }


  function redirectPINPage() {
    if (details.service == "Recharge Pin") {
      window.location.href = baseURL+"/recharge/pin/"+user().username+"/"+details.reference;      
    } else if (details.service == "Result Checker") {
      window.location.href = baseURL+"/exam/pin/"+user().username+"/"+details.reference;
    } else if (details.service == "Spectranet") {
      window.location.href = baseURL+"/spectranet/pin/"+user().username+"/"+details.reference;
    } else if (details.service == "Data Card") {
      window.location.href = baseURL+"/data-pin/pin/"+user().username+"/"+details.reference;
    }
  }



  return (


    <div>
      

      <div className="page-title page-title-fixed">
<h1>Receipt</h1>
{/* <a href="#" className="page-title-icon shadow-xl bg-theme color-theme" data-menu="menu-share"><i className="fa fa-share-alt"></i></a>
<a href="#" className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light" data-toggle-theme><i className="fa fa-moon"></i></a>
<a href="#" className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark" data-toggle-theme><i className="fa fa-lightbulb color-yellow-dark"></i></a> */}
<a href={void(0)} onClick={() => navigate("/")} className="page-title-icon shadow-xl bg-theme color-theme" style={{ width: "100px", cursor: "context-menu" }}><i className="fa fa-arrow-left"></i> Go Home </a>
</div>


<div className="page-title-clear"></div>
<div className="page-content">
<div className="card dashboard-layout card-style">

<img src="/dashboard/images/emoji.jpg" width="80" className={"mx-auto mt-4" + Styles.receipt_img } style={{ marginBottom: "10px", marginTop: "12px" }} />
    <h1 className="text-center" style={{ fontSize: "20px", paddingBottom: "0px" }}>Transaction Receipt</h1>

    <p className="boxed-text-xl" style={{ marginBottom: "-5px", lineHeight: "21px" }}>
    Thank you for choosing us!. Kindly reach out to us if the value of this transaction is not received.
    </p>



<div className="content" style={{ margin: "0px 15px 0px 15px" }}>

  {/* STAMP  */}
  <div className='stamp'> {siteName} </div>

<div className="row mb-0 mt-4">

<h5 className="col-4 text-start font-14">Service</h5>
<h5 className="col-8 text-end font-13 font-600">{details.service} </h5>

<div className="divider divider-margins w-100 mt-2 mb-2"></div>


<h5 className="col-4 text-start font-14">Invoice</h5>
<h5 className="col-8 text-end font-13 font-600">{details.reference} </h5>

<div className="divider divider-margins w-100 mt-2 mb-2"></div>

<h5 className="col-4 text-start font-14">Balance</h5>
<h5 className="col-8 text-end font-13 font-600"> &#8358;{details.balance_after} </h5>

<div className="divider divider-margins w-100 mt-2 mb-2"></div>

<h5 className="col-4 text-start font-14">Number</h5>
<h5 className="col-8 text-end font-13 font-600">{details.phone_number}</h5>

<div className="divider divider-margins w-100 mt-2 mb-2"></div>

<h5 className="col-4 text-start font-14">Date/Time</h5>
<h5 className="col-8 text-end font-13 font-600 pb-2">{date(details.date)}</h5>
</div>

<div className="divider mt-0 mb-2"></div>


<div className="d-flex mb-2">
<div>
<TranxImg status={details.status}/>
</div>
<div className="ps-3 w-100">
<h1 style={{ fontSize: "14px", paddingBottom: "0px", marginBottom: "0px" }}> 

Amount: <span className='font-600'> &#8358;{details.amount} </span>

<span className="mb-0 color-highlight font-13 font-500 float-end">Type: Debit</span> </h1>

<div className="divider divider-margins w-100 mt-1 mb-2"></div>

<h5 className="font-600 font-13" style={{ wordBreak: "break-all" }}>{details.description}</h5>
</div>

</div>





<div className="divider mt-1 mb-2"></div>

<div className='row mb-2'>
<h5 className="col-4 text-start font-14">Status</h5>
<h5 className="col-8 text-end font-15 font-400"> 
  <TranxStatus status={details.status} />
</h5>
</div>


{/* Electricity Token value */}
<span hidden={details.remark !== "null" && details.remark !== "" ? false : true}>
<div className="divider mt-1 mb-2"></div>
<div className='row mb-2'>
<h5 className="col-4 text-start font-14">Value</h5>
<h5 className="col-8 text-end font-13 font-600" style={{ wordBreak: 'break-all' }}>{details.remark} </h5>

</div>
</span>




<div className='row' style={{ justifyContent: "space-around" }} hidden={details.service == "Result Checker" || details.service == "Recharge Pin" || details.service == "Spectranet" || details.service == "Data Card" ? true : false}>
  <a href={void(0)} className="btn btn-sm btn-l rounded-s font-600 col-6" onClick={() => window.print()} style={{ width: "45%", backgroundColor: "green" }}>Print <i className="fa fa-print" aria-hidden="true"></i></a>
  <a href={void(0)} onClick={downloadPDF} className="btn btn-sm btn-l rounded-s font-600 col-6" style={{ width: "45%", backgroundColor: "red" }}>
  
    { loading ? 'Generating...' : '' }
    { loading ? '' : 'Download' } { loading ? '' : <i className="fa fa-download" aria-hidden="true"></i> }

  </a>
</div>


<a hidden={details.service == "Result Checker" || details.service == "Recharge Pin" || details.service == "Data Card" || details.service == "Spectranet" ? false : true} href={void(0)} className="btn btn-sm btn-l rounded-s font-600 mb-3" onClick={() => redirectPINPage() } style={{ width: "100%", backgroundColor: "green" }}>View PIN <i className="fa fa-eye" aria-hidden="true"></i></a>


</div>
</div>


</div>





    </div>

  )

}

export default Receipt;
